body {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-family:
    Roboto Flex,
    Graphik,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-weight: 400;
  line-height: 1.43;
  background: linear-gradient(135deg, #f9f6f6 14.64%, #f5f5f8 42.26%);
}

:root {
  --muted: #aaaaaa;
  --brand: #5951ff;
  --brand-muted: #5e5eaf;
  --secondary: #ffa8a8;
  --disabled: #565465;
}

#root {
  min-height: 100vh;
}

.min-h-100vh {
  min-height: 100vh !important;
}

.w-75 {
  width: 75% !important;
}

.w-200px {
  width: 200px !important;
}

.w-300px {
  width: 300px !important;
}

.top-0 {
  top: 0 !important;
}

.top-1 {
  top: 1rem !important;
}

.right-0 {
  right: 0 !important;
}

.right-1 {
  right: 1rem !important;
}

.badge-primary {
  background-color: var(--bs-primary);
}

.badge-danger {
  background-color: var(--bs-danger);
}

.badge-info {
  background-color: var(--bs-info);
}

.badge-brand {
  background-color: var(--brand);
}

.cursor-pointer {
  cursor: pointer;
}

.text-small {
  font-size: 0.75rem;
}

.text-thin {
  font-weight: 200;
}

.text-bold {
  font-weight: 400;
}

.text-muted {
  color: var(--muted) !important;
}

.bg-brand {
  background-color: var(--brand);
}

.opacity-50 {
  opacity: 0.5;
}

.form-control {
  border-radius: 6px;
  font-weight: 400;
  border: 1px solid var(--muted);
  transition: border-color 190ms ease-in;
}

.form-control:focus {
  border-color: var(--brand) !important;
  outline: none;
  box-shadow: 0 0 0 1px var(--brand);
}

.link {
  color: var(--brand);
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
}

.link:hover {
  color: var(--brand);
  text-decoration: underline;
}

.btn {
  border-radius: 99999px;
  font-size: 0.875rem;
}

.btn-brand {
  color: #ffffff;
  background-color: var(--brand);
  border: none;
  transition:
    opacity 0.25s,
    outline 0.25s;
  cursor: pointer;
}

.btn-brand:hover {
  opacity: 0.8;
  color: #ffffff;
  background-color: var(--brand);
}

.btn-brand:active {
  opacity: 1;
  color: #ffffff !important;
  background-color: var(--brand) !important;
}

.btn-brand:focus,
.btn-brand:focus-visible,
.btn-brand:focus-within,
.form-select:focus,
.form-select:focus-visible,
.form-select:focus-within {
  outline: 1px solid var(--brand) !important;
  outline-offset: 2px;
}

.btn-brand:focus,
.btn-brand:focus-visible,
.btn-brand:focus-within {
  opacity: 0.8;
  color: #ffffff;
  background-color: var(--brand);
}

.btn-brand:disabled {
  opacity: 0.75;
  color: #dddddd;
  background-color: var(--disabled);
  cursor: not-allowed;
}

.nav-tabs .nav-link {
  color: var(--brand-muted);
  margin-bottom: 1px;
  border-radius: 8px 8px 0 0;
  padding: 8px;
  font-size: 0.875rem;
  color: var(--muted);
  border: none;
  transition:
    opacity 0.25s,
    outline 0.25s;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
}

.nav-tabs .nav-link:hover {
  background-color: rgba(94, 94, 175, 0.04);
}

.nav-tabs .nav-link.active {
  color: var(--brand);
  outline: 1px solid #fff;
  background: linear-gradient(0deg, #f2f2ff, #f2f2ff),
    linear-gradient(
      99.8deg,
      rgba(0, 0, 0, 0.016) 0%,
      rgba(255, 255, 255, 0.048) 100%
    ),
    linear-gradient(0deg, #fefdfc, #fefdfc);
  box-shadow:
    inset 0px -2px 6px 0px rgba(255, 255, 255, 0.9),
    inset 0px 2px 6px 0px rgba(94, 94, 175, 0.2),
    inset 0px 2px 4px 0px rgba(94, 94, 175, 0.2);
}

.nav-tabs .nav-link.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation-fade-out {
  animation: fadeOut 0.5s ease-in-out;
}

.animation-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.firebase-emulator-warning {
  color: var(--muted) !important;
  position: absolute;
  bottom: 1.5rem !important;
  font-size: 0.75rem;
  border: none !important;
  background-color: transparent !important;
}

/*
Breakpoints
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1400px
*/

@media screen and (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .w-lg-400px {
    width: 400px !important;
  }

  .w-lg-500px {
    width: 500px !important;
  }
}
